Widgets.Group = Class.create(Widgets.Base, {
    name:		'group',

    css:        `
        .widget.group {
            margin: 0;
        }

        .widget.group[data-style=placeholder] {
            height: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8em;
        }

        .widget.group[data-style=placeholder] div::before {
            display: block;
            content: '\\e91d';
            margin-bottom: 6px;
            font-family: SalonIcon, SalonWidgets;
            font-size: 28px;
            text-align: center;
            color: var(--colors-gray);
        }
    `,

    initWidget: function() {
        if (this.options.style) this.dataset.style = this.options.style;

        this.container = new Element('div');
        this.insert(this.container);

        if (this.options.template) {
            if (typeof this.options.template == 'string') {
                this.container.innerHTML = this.options.template;
            }
            else {
                render(this.container, this.options.template);
            }

            /* Capture link clicks in templates and use the application handler for them */
            this.container.addEventListener('click', (e) => {
                if (e.target.tagName == 'A') {
                    if (e.target.href.substring(0,4) == 'http') {
                        e.preventDefault();

                        if (Application.openInBrowser) {
                            Application.openInBrowser(e.target.href);
                        }

                        if (typeof Runtime !== 'undefined' && Runtime.Platform) {
                            Runtime.Platform.openInBrowser(e.target.href);
                        }
                    }
                }
            })
        }

        if (this.options.requirement == 'locked') {
            this.locked = true;
            this.unlocked = false;

            document.observe('application:lock', this.show.bind(this));
            document.observe('application:unlock', this.hide.bind(this));

            if (!Application.locked) {
                this.hide();
            }
        }

        else if (this.options.requirement == 'unlocked') {
            this.locked = false;
            this.unlocked = true;

            document.observe('application:lock', this.hide.bind(this));
            document.observe('application:unlock', this.show.bind(this));

            if (Application.locked) {
                this.hide();
            }
        }
    },

    clear: function() {
        this.container.update('');
    },

    update:	function(data) {
        this.container.innerHTML = data;
    },

    appendChild: function(element) {
        this.container.appendChild(element);
    }
});
